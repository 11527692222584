/* Media Queries */
/* Mobile */
@media screen and (min-width: 330px) and (max-width: 369px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 1.1rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 1.7rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.3rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media screen and (min-width: 370px) and (max-width: 399px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 1.1rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 1.7rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.3rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media screen and (min-width: 400px) and (max-width: 574px){
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 1.3rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 2rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media screen and (min-width: 575px) and (max-width: 749px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 1.3rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 2rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media screen and (min-width: 750px) and (max-width: 869px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 1.7rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 2.7rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.7rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media screen and (min-width: 870px) and (max-width: 909px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 1.3rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 2rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media screen and (min-width: 910px) and (max-width: 1023px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        margin-top: 3vh;
        font-size: 2.3rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 3.7rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 2.3rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

/* Desktops */
@media only screen and (min-width: 1024px) and (max-width: 1119px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        font-size: 1.5rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 3rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media only screen and (min-width: 1120px) and (max-width: 1199px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        font-size: 1.5rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 3rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        font-size: 1.5rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 3rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        font-size: 1.5rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 3rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}

@media only screen and (min-width: 1600px) {
    .contact-content {
        margin-bottom: 20vh;
    }

    .email-caption {
        font-size: 1.5rem;
    }

    .email-caption-light {
        text-decoration: none;
        color: #cfcfcf;
        cursor: pointer;
    }

    .email {
        display: inline-block;
        font-size: 3rem;
        font-weight: bolder;
        cursor: pointer;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s ease;
    }

    .location-detail {
        margin-top: 3vh;
        font-size: 1.5rem;
    }

    .social-media {
        margin-top: 3vh;
    }

    .social-icon {
        margin: 0 1rem;
        cursor: pointer;
    }
}
