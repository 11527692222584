#tsparticles {
    pointer-events: none;
    height: 100% !important;
    position: fixed !important;
    z-index: 0 !important;
    top: 0px !important;
    width: 100% !important;
    left: 0px !important;
    background: linear-gradient(180deg, rgba(73,73,73,1) 0%, rgba(194,194,194,1) 100%);
}
