/* Media Queries */
/* Mobile */
@media screen and (min-width: 330px) and (max-width: 369px) {
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 18vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 2vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 3.8vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 4vw;
        height: 4vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 4.5vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5vw;
        padding: 2vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 5vw;
        height: 5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media screen and (min-width: 370px) and (max-width: 399px) {
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 22vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 1vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 3.8vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 4vw;
        height: 4vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 4.5vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5vw;
        padding: 2vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 5vw;
        height: 5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media screen and (min-width: 400px) and (max-width: 574px) {
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 22vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 1vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 3.5vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 3.5vw;
        height: 3.5vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 4vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4.5vw;
        padding: 2vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 4.5vw;
        height: 4.5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media screen and (min-width: 575px) and (max-width: 749px){
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 25vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 2vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 2vw;
        height: 2vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 2.5vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5vw;
        padding: 1.3vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 2.5vw;
        height: 2.5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media screen and (min-width: 750px) and (max-width: 869px) {
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 33vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 2.5vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 3vw;
        height: 3vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 3vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3vw;
        padding: 1.5vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 3vw;
        height: 3vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media screen and (min-width: 870px) and (max-width: 909px) {
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 25vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 3.8vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 4vw;
        height: 4vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 3vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5vw;
        padding: 2vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 5vw;
        height: 5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media screen and (min-width: 910px) and (max-width: 1023px) {
    .project-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        padding-top: 5vh;
        border-radius: 1rem;
    }

    .project-image img {
        height: 27vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .project-content h3 {
        font-size: 4vh;
        margin-top: 1.5vh;
        margin-bottom: 1vh;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        margin: 0;
    }

    .project-content li {
        display: flex;
        font-size: 3.8vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 2vw;
        padding-right: 2vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 4vw;
        height: 4vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 3vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-right: auto;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 5vw;
        padding: 2vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link img {
        width: 5vw;
        height: 5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

/* Desktops */
@media only screen and (min-width: 1024px) and (max-width: 1119px) {
    .project-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        margin-right: 5vw;
        margin-left: 5vw;
        border-radius: 1rem;
        align-items: stretch;
        transition: 0.5s;
    }

    .project-container:hover {
        transform: scale(1.1);
        background-color: #00000018;
    }

    .project-image {
        flex: 1;
        margin-left: 2vw;
        margin-right: 2vw;
        align-content: center;
    }

    .project-image img {
        height: 20vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
        cursor: pointer;
        transition: 0.5s;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: left;
        margin-left: 3vw;
    }

    .project-content h3 {
        color: var(--accent);
        font-size: 2.5vw;
        margin: 0;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        cursor: default;
    }

    .project-content li {
        display: flex;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 1.5vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-top: auto;
        margin-right: 3vw;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1.7vw;
        padding: 0.6vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link:hover {
        transform: scale(1.05);
        background-color: #3a3a3a;
    }

    .button-link img {
        width: 1.7vw;
        height: 1.7vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media only screen and (min-width: 1120px) and (max-width: 1199px) {
    .project-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        margin-right: 5vw;
        margin-left: 5vw;
        border-radius: 1rem;
        align-items: stretch;
        transition: 0.5s;
    }

    .project-container:hover {
        transform: scale(1.1);
        background-color: #00000018;
    }

    .project-image {
        flex: 1;
        margin-left: 2vw;
        margin-right: 2vw;
        align-content: center;
    }

    .project-image img {
        height: 27vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
        cursor: pointer;
        transition: 0.5s;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: left;
    }

    .project-content h3 {
        color: var(--accent);
        font-size: 2.5vw;
        margin: 0;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        cursor: default;
    }

    .project-content li {
        display: flex;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 1.5vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-top: auto;
        margin-right: 3vw;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1.5vw;
        padding: 0.6vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link:hover {
        transform: scale(1.05);
        background-color: #3a3a3a;
    }

    .button-link img {
        width: 1.5vw;
        height: 1.5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .project-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        margin-right: 5vw;
        margin-left: 5vw;
        border-radius: 1rem;
        align-items: stretch;
        transition: 0.5s;
    }

    .project-container:hover {
        transform: scale(1.1);
        background-color: #00000018;
    }

    .project-image {
        flex: 1;
        margin-left: 2vw;
        margin-right: 2vw;
        align-content: center;
    }

    .project-image img {
        height: 27vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
        cursor: pointer;
        transition: 0.5s;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: left;
    }

    .project-content h3 {
        color: var(--accent);
        font-size: 2.5vw;
        margin: 0;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        cursor: default;
    }

    .project-content li {
        display: flex;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 1.5vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-top: auto;
        margin-right: 3vw;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1.5vw;
        padding: 0.6vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link:hover {
        transform: scale(1.05);
        background-color: #3a3a3a;
    }

    .button-link img {
        width: 1.5vw;
        height: 1.5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .project-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        margin-right: 5vw;
        margin-left: 5vw;
        border-radius: 1rem;
        align-items: stretch;
        transition: 0.5s;
    }

    .project-container:hover {
        transform: scale(1.1);
        background-color: #00000018;
    }

    .project-image {
        flex: 1;
    }

    .project-image img {
        height: 28vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
        cursor: pointer;
        transition: 0.5s;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: left;
        margin-left: 2vw;
    }

    .project-content h3 {
        color: var(--accent);
        font-size: 2.5vw;
        margin: 0;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        cursor: default;
    }

    .project-content li {
        display: flex;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 1.2vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-top: auto;
        margin-right: 3vw;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1.5vw;
        padding: 0.6vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link:hover {
        transform: scale(1.05);
        background-color: #3a3a3a;
    }

    .button-link img {
        width: 1.5vw;
        height: 1.5vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}


@media only screen and (min-width: 1600px) {
    .project-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 5vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        margin-right: 5vw;
        margin-left: 5vw;
        border-radius: 1rem;
        align-items: stretch;
        transition: 0.5s;
    }

    .project-container:hover {
        transform: scale(1.1);
        background-color: #00000018;
    }

    .project-image {
        flex: 1;
    }

    .project-image img {
        height: 30vh;
        border-radius: 1rem;
        border: 0.1vh solid white;
        cursor: pointer;
        transition: 0.5s;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: left;
    }

    .project-content h3 {
        color: var(--accent);
        font-size: 2.5vw;
        margin: 0;
    }

    .project-content ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        gap: 0.1vw;
        padding: 0;
        cursor: default;
    }

    .project-content li {
        display: flex;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 1rem;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    .project-content li img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.4vw;
    }

    .project-content p {
        font-size: 1.2vw;
    }

    .project-links {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 1vw;
        margin-left: auto;
        margin-top: auto;
        margin-right: 3vw;
    }

    .button-link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1vw;
        padding: 0.6vw;
        border: 0.1vh solid #c7c7c7;
        border-radius: 1rem;
        background-color: #3a3a3ac4;
        text-decoration: none;
        color: white;
        transition: 0.5s;
    }

    .button-link:hover {
        transform: scale(1.05);
        background-color: #3a3a3a;
    }

    .button-link img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.5vw;
        transition: 0.5s;
    }
}
