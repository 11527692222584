/* Home Styles */
.home-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 8%;
}

.main-content {
    height: 100vh;
}

/* Media Queries */
/* Mobile */
@media screen and (min-width: 330px) and (max-width: 369px) {
    .greetings h1 {
        margin-top: 20vh;
        font-size: 8vw;
    }

    .secondary-title {
        padding-top: 10vh;
        font-size: 8vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 6vw;
    }

    .descriptions-container {
        margin-top: 30%;
        font-size: 5vw;
    }

    .description-2 {
        font-size: 4vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 10vh;
        font-size: 10vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 5vw;
    }
}

@media screen and (min-width: 370px) and (max-width: 399px) {
    .greetings h1 {
        margin-top: 20vh;
        font-size: 8vw;
    }

    .secondary-title {
        padding-top: 10vh;
        font-size: 8vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 6vw;
    }

    .descriptions-container {
        margin-top: 15%;
        font-size: 4vw;
    }

    .description-2 {
        font-size: 3.5vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 12vh;
        font-size: 10vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 5vw;
    }
}

@media screen and (min-width: 400px) and (max-width: 574px){
    .greetings h1 {
        margin-top: 20vh;
        font-size: 8vw;
    }

    .secondary-title {
        padding-top: 10vh;
        font-size: 8vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 6vw;
    }

    .descriptions-container {
        margin-top: 20%;
        font-size: 4vw;
    }

    .description-2 {
        font-size: 3.5vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 10vh;
        font-size: 10vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 5vw;
    }
}

@media screen and (min-width: 575px) and (max-width: 749px) {
    .greetings h1 {
        margin-top: 25vh;
        font-size: 8vw;
    }

    .secondary-title {
        padding-top: 12.5vh;
        font-size: 8vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 6vw;
    }

    .descriptions-container {
        margin-top: 8%;
        font-size: 4vw;
    }

    .description-2 {
        font-size: 3.5vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 10vh;
        font-size: 10vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 5vw;
    }
}

@media screen and (min-width: 750px) and (max-width: 869px) {
    .greetings h1 {
        margin-top: 20vh;
        font-size: 7.9vw;
    }

    .secondary-title {
        padding-top: 10vh;
        font-size: 7.9vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 5vw;
    }

    .descriptions-container {
        margin-top: 8%;
        font-size: 3.5vw;
    }

    .description-2 {
        font-size: 3vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 10vh;
        font-size: 8vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 4vw;
    }
}

@media screen and (min-width: 870px) and (max-width: 909px) {
    .greetings h1 {
        margin-top: 25vh;
        font-size: 8vw;
    }

    .secondary-title {
        padding-top: 12.5vh;
        font-size: 8vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 6vw;
    }

    .descriptions-container {
        margin-top: 8%;
        font-size: 4vw;
    }

    .description-2 {
        font-size: 3.5vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 10vh;
        font-size: 10vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 5vw;
    }
}

@media screen and (min-width: 910px) and (max-width: 1023px) {
    .greetings h1 {
        margin-top: 17vh;
        font-size: 8vw;
    }

    .secondary-title {
        padding-top: 8.5vh;
        font-size: 8vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 6vw;
    }

    .descriptions-container {
        margin-top: 14%;
        font-size: 4vw;
    }

    .description-2 {
        font-size: 3.5vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 10vh;
        font-size: 8vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 4vw;
    }
}

/* Desktops */
@media only screen and (min-width: 1024px) and (max-width: 1119px) {
    .greetings h1 {
        margin-top: 20vh;
        font-size: 7vw;
    }

    .secondary-title {
        padding-top: 10vh;
        padding-bottom: 4%;
        font-size: 5vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 4vw;
    }

    .descriptions-container {
        margin-top: 10%;
        font-size: 2vw;
    }

    .description-2 {
        font-size: 1.8vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 7vh;
        font-size: 5vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 2vw;
    }
}

@media only screen and (min-width: 1120px) and (max-width: 1199px) {
    .greetings h1 {
        margin-top: 16vh;
        font-size: 7vw;
    }

    .secondary-title {
        padding-top: 8vh;
        padding-bottom: 4%;
        font-size: 5vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 3.5vw;
    }

    .descriptions-container {
        margin-top: 8%;
        font-size: 2vw;
    }

    .description-2 {
        font-size: 1.7vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 9vh;
        font-size: 3.8vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 1.8vw;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .greetings h1 {
        margin-top: 16vh;
        font-size: 7vw;
    }

    .secondary-title {
        padding-top: 8vh;
        padding-bottom: 4%;
        font-size: 5vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 3.5vw;
    }

    .descriptions-container {
        margin-top: 8%;
        font-size: 2vw;
    }

    .description-2 {
        font-size: 1.7vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 9vh;
        font-size: 3.8vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 1.8vw;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .greetings h1 {
        margin-top: 16vh;
        margin-bottom: 3vh;
        font-size: 6vw;
    }

    .secondary-title {
        padding-top: 8vh;
        padding-bottom: 4%;
        font-size: 5vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 3vw;
    }

    .descriptions-container {
        margin-top: 5%;
        font-size: 2vw;
    }

    .description-2 {
        font-size: 1.6vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 7vh;
        font-size: 4vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 2vw;
    }
}

@media only screen and (min-width: 1600px) {
    .greetings h1 {
        margin-top: 15vh;
        font-size: 6vw;
    }

    .secondary-title {
        padding-top: 7.5vh;
        padding-bottom: 4%;
        font-size: 4.5vw;
        margin-bottom: 0;
        left: default;
    }

    .Typewriter {
        font-size: 1.8vw;
    }

    .descriptions-container {
        margin-top: 8%;
        font-size: 1.5vw;
    }

    .description-2 {
        font-size: 1.3vw;
        color: #f0f0f0;
    }

    .arrow-down {
        margin-top: 15vh;
        font-size: 3.8vw;
        color: #f1f1f1;
        cursor: pointer;
    }

    .arrow-down p {
        margin-top: 0;
        font-size: 1.8vw;
    }
}
